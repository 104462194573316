body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of other elements */
  }
  
  .loader-content {
    color: #fff;
    font-size: 1.5em;
  }
  